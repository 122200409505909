import * as React from 'react';
import * as CSS from 'csstype';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MAvatar from '@material-ui/core/Avatar';

import { BlockHead, Box } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    large: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
    largeRounded: {
      width: theme.spacing(20),
      height: theme.spacing(20),
      borderRadius: '100px',
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

export interface AvatarProps {
  children?: React.ReactNode;
  name: string;
  mode: any;
  style?: CSS.Properties;
  rounded?: boolean;
}

/**
 * TEMPLATE FILE
 * @param AvatarProps
 */
const Avatar = ({ children, name, mode, rounded }: AvatarProps) => (
  <Box className={useStyles().container}>
    {/* <MAvatar
      // variant={rounded ? 'rounded' : 'square'}
      className={rounded ? useStyles().largeRounded : useStyles().large}
      alt='Remy Sharp'
      src='./dummy.webp'
      width={'100%'}
      style={{ margin: 'auto' }}
    /> */}
    <BlockHead
      mode={mode || 'light'}
      size={'md'}
      title={Array.isArray(children) ? ['', name, ...children] : ['', name, children]}
    />
  </Box>
);

export { Avatar };
