import * as React from 'react';
import * as CSS from 'csstype';
import MIcon from '@material-ui/core/Icon';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
// import { fade } from '@material-ui/core/styles/colorManipulator';

interface MIconProps {
  component: HTMLElementTagNameMap;
  color: 'inherit' | 'primary' | 'secondary' | 'action' | 'error' | 'disabled';
  fontSize: 'inherit' | 'default' | 'small' | 'large';
  // spacing?: number;
  // spacingH?: number;
  // spacingV?: number;
}

const useStyles = ({}: /* spacing, spacingH, spacingV */ MIconProps) =>
  makeStyles<Theme, MIconProps>((theme) =>
    createStyles({
      grid: {
        // backgroundColor: `${fade(theme.palette.primary.main, 0.95)} !important`, // use for debug with important
        // padding: `${theme.spacing(spacingV || spacing || 2)}px
        //   ${theme.spacing(spacingH || spacing || 2)}px`,
      },
    }),
  );

// navigate pathContext pageContext pageResources
export interface IconProps extends MIconProps {
  children?: React.ReactNode;
  className?: string;
  style?: CSS.Properties;
}

// FIXME: declare global or import
interface AppProps {
  navigate: any;
  pageContext: any;
  pageResources: any;
  pathContext: any;
}

const Icon = (props: IconProps) => {
  const { children, className, style, ...mGridProps } = props;
  const styles = useStyles(mGridProps)(props);
  return (
    <DoneOutlineIcon
      fontSize='large'
      className={`${styles.grid} ${className || ''}`}
      style={style}
      {...mGridProps}
    >
      {children}
    </DoneOutlineIcon>
  );
};

// Create HOC
const AppPropsBlocker = (props: IconProps & Partial<AppProps>) => {
  const { children, navigate, pageContext, pageResources, pathContext, ...gridProps } = props;
  return <Icon {...gridProps}>{children}</Icon>;
};

export { AppPropsBlocker as Icon };
