import React from 'react';
import { makeStyles, Theme, createStyles, withStyles } from '@material-ui/core/styles';
import MAccordion, { AccordionProps as MAccordionProps } from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { CombinedAppProps, CombinedBaseComponentProps, withoutAppProps } from 'components';

const StyledAccordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    borderRadius: '30px 0px 30px 0',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MAccordion);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: '#ffffff00',
      borderWidth: '0px',
      padding: '0px',
      '&:before': {
        display: 'none',
      },
      '&:after': {
        display: 'none',
      },
    },
  }),
);

export interface AccordionProps extends CombinedBaseComponentProps<MAccordionProps> {
  summary?: React.ReactNode | React.ReactNode[] | null;
  expandIcon?: React.ReactNode;
  ariaControls?: string;
  id?: string;
  hideOnShrink?: boolean;
  hideSummary?: boolean;
}

const Accordion = (props: AccordionProps) => {
  const {
    ariaControls,
    children,
    className,
    expandIcon,
    hideOnShrink,
    hideSummary,
    id,
    summary,
    ...passProps
  } = props;
  const classes = useStyles();
  // const [expanded, setExpanded] = React.useState<string | false>(false);

  // const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
  //   setExpanded(isExpanded ? panel : false);
  // };

  return (
    <StyledAccordion className={`${className || ''} ${classes.root}`} {...passProps}>
      {!hideSummary ? (
        <AccordionSummary
          // expandIcon={expandIcon || <ExpandMoreIcon />}
          expandIcon={expandIcon}
          aria-controls={ariaControls || `accordion-content-${id}`}
          id={id || 'accordion-header'}
          style={{ padding: '0' }}
        >
          {summary}
        </AccordionSummary>
      ) : null}
      <AccordionDetails style={{ padding: '0' }}>{children}</AccordionDetails>
    </StyledAccordion>
  );
};

const WAP = (props: CombinedAppProps<AccordionProps>) =>
  withoutAppProps<CombinedAppProps<AccordionProps>, AccordionProps>(Accordion, props);

export { Accordion as BAccordion, WAP as Accordion };
