import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
// import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((/* theme: Theme */) =>
  createStyles({
    span: {},
  }));

export interface SpanProps {
  children: React.ReactNode;
  className: string; // TODO: create template with styling similar to Box.tsx
  style?: any;
}

const Span = ({ children, style }: SpanProps) => (
  <span className={useStyles().span} style={style}>
    {children}
  </span>
);

export { Span };
