import * as React from 'react';
import MCard from '@material-ui/core/Card';

export interface CardProps {
  children?: React.ReactNode;
}

/**
 * A card composed from MaterialUI
 * @param CardProps
 */
const Card = ({ children }: CardProps) => <MCard>{children}</MCard>;

export { Card };
