import * as React from 'react';
import * as CSS from 'csstype';
import MGrid from '@material-ui/core/Grid';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
// import { fade } from '@material-ui/core/styles/colorManipulator';

interface MGridProps {
  // spacing?: number;
  // spacingH?: number;
  // spacingV?: number;
}

const useStyles = ({}: /* spacing, spacingH, spacingV */ MGridProps) =>
  makeStyles<Theme, MGridProps>((theme) =>
    createStyles({
      grid: {
        // backgroundColor: `${fade(theme.palette.primary.main, 0.95)} !important`, // use for debug with important
        // padding: `${theme.spacing(spacingV || spacing || 2)}px
        //   ${theme.spacing(spacingH || spacing || 2)}px`,
      },
    }),
  );

// navigate pathContext pageContext pageResources
export interface GridProps extends MGridProps {
  children?: React.ReactNode;
  className?: string;
  style?: CSS.Properties;
}

// FIXME: declare global or import
interface AppProps {
  navigate: any;
  pageContext: any;
  pageResources: any;
  pathContext: any;
}

const Grid = (props: GridProps) => {
  const { children, className, style, ...mGridProps } = props;
  const styles = useStyles(mGridProps)(props);
  return (
    <MGrid className={`${styles.grid} ${className || ''}`} style={style} {...mGridProps}>
      {children}
    </MGrid>
  );
};

// Create HOC
const AppPropsBlocker = (props: GridProps & Partial<AppProps>) => {
  const { children, navigate, pageContext, pageResources, pathContext, ...gridProps } = props;
  return <Grid {...gridProps}>{children}</Grid>;
};

export { AppPropsBlocker as Grid };
