import * as React from 'react';

import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import { ScrollToTop } from './ScrollToTop';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      backgroundColor: theme.palette.common.white,
      elevation: 24,
    },
    linkButton: {
      // backgroundColor: '#f00',
      // color: '#0f0',
      // '&:hover': {
      //   backgroundColor: '#f00',
      //   color: '#0f0',
      // },
    },
  }),
);

interface ScrollToTopContainerProps {}

const ScrollToTopContainer: React.FC<ScrollToTopContainerProps> = (
  props: ScrollToTopContainerProps,
) => {
  const classes = useStyles();
  return (
    <ScrollToTop window={typeof window !== 'undefined' ? window : undefined}>
      <Fab className={classes.fab} size='small' aria-label='scroll back to top'>
        <KeyboardArrowUpIcon />
      </Fab>
    </ScrollToTop>
  );
};

// rename exports
export { ScrollToTopContainer as ScrollToTop };
export type ScrollToTopProps = ScrollToTopContainerProps;
