import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    em: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
  }),
);

export interface EmProps {
  children?: React.ReactNode;
}

/**
 * TEMPLATE FILE
 * @param TsxProps
 */
const Em = ({ children }: EmProps) => <strong className={useStyles().em}>{children}</strong>;

export { Em };
