import * as React from 'react';
import MTypography, { TypographyProps as MTypographyProps } from '@material-ui/core/Typography';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { CombinedAppProps, CombinedBaseComponentProps, withoutAppProps } from 'components';

const useStyles = (props: MTypographyProps) =>
  makeStyles<Theme, MTypographyProps>((/* theme: Theme */) =>
    createStyles({
      typography: {
        wordWrap: 'break-word',
        // color: theme.palette.getContrastText(theme.palette.text.primary),
        // textAlign: 'left',
      },
    }));

export interface TypographyProps extends CombinedBaseComponentProps<MTypographyProps> {
  renderAs?: string; // Component is also possible
}

const Typography = (props: TypographyProps) => {
  const { renderAs, children, className, ...passProps } = props;
  const styleClassName = useStyles(passProps)(props);
  return (
    <MTypography
      component={renderAs as any}
      variantMapping={{
        h1: 'h1',
        h2: 'h2',
        h3: 'h3',
        h4: 'h4',
        h5: 'h5',
        h6: 'h6',
        subtitle1: 'h6',
        subtitle2: 'h6',
        body1: 'p',
        body2: 'p',
      }}
      className={`${styleClassName.typography} ${className || ''}`}
      {...passProps}
    >
      {children}
    </MTypography>
  );
};

const WAP = (props: CombinedAppProps<TypographyProps>) =>
  withoutAppProps<CombinedAppProps<TypographyProps>, TypographyProps>(Typography, props);

export { Typography as BTypography, WAP as Typography };
