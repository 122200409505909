import React from 'react';
import { StyleContext } from './styleContext.types';

export const styleContext = React.createContext<StyleContext | null>(null);

// since the app is wrapped in a themeProvider, we assume that context cannot be null
export const useStyleContext: () => StyleContext = (): StyleContext =>
  React.useContext<StyleContext | null>(styleContext) as StyleContext;

export default styleContext;
