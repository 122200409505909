import * as React from 'react';
import { Box, Container } from 'components';

export interface ContentContainerProps {
  children?: React.ReactNode;
  id?: string;
  isHidden?: boolean;
  isTransparent?: boolean;
}

/**
 * A responsive/fluid container for content inside a full-width container
 * @param ContentContainerProps
 */
const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  id,
  isHidden,
  isTransparent,
  ...rest
}: ContentContainerProps) => {
  if (isHidden) return null;
  return isTransparent ? (
    <div id={id}>
      <Container {...rest}>
        <Box>{children}</Box>
      </Container>
    </div>
  ) : (
    <div id={id} style={{ backgroundColor: '#fff' }}>
      <Container {...rest}>
        <Box>{children}</Box>
      </Container>
    </div>
  );
};

export { ContentContainer };
