import * as React from 'react';
import * as CSS from 'csstype';
import styled from 'styled-components';
import { TextField as MTextField } from '@material-ui/core';

const StyledTextField = styled(MTextField)`
  label {
    color: #000;
  }
  label.focused {
    color: #f44336;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-color: #fff;
    }
    &:hover fieldset {
      border-color: #f44336;
    }
    &.Mui-focused fieldset {
      border-color: #f44336;
    }
  }
  .MuiFilledInput-root {
    color: #000;
    background-color: #eee;
    border-color: #fff;
    fieldset {
      color: #000;
      background-color: #f00;
      border-color: #0f0;
    }
    &:hover fieldset {
      border-color: #f44336;
    }
    &.Mui-focused fieldset {
      border-color: #f44336;
    }
  }
`;

export interface TextInputFieldProps {
  children?: React.ReactNode;
  className?: string;
  style?: CSS.Properties;
}

const TextInputField = (props: TextInputFieldProps) => {
  const { children, className, style, ...passProps } = props;
  return <StyledTextField {...passProps}>{children}</StyledTextField>;
};

export { TextInputField };
