import * as React from 'react';
import { CSSProperties } from 'styled-components';

export interface ColumnProps {
  children?: React.ReactNode;
  style?: CSSProperties;
}

/**
 * Column for flex container
 * @param ColumnProps
 */
// * + * = margin-left xy
const Column = ({ children, style }: ColumnProps) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      flexGrow: 1,
      ...style,
    }}
  >
    {children}
  </div>
);

export { Column };
