import { useState, useEffect } from 'react';

/**
 * useScroll React custom hook
 * Usage:
 *    const { scrollX, scrollY, scrollDirection } = useScroll();
 */
export function useScroll() {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [bodyOffset, setBodyOffset] = useState(
    typeof window !== 'undefined' ? window.document.body.getBoundingClientRect() : null,
  );
  const [scrollY, setScrollY] = useState(bodyOffset?.top ?? 0);
  const [scrollX, setScrollX] = useState(bodyOffset?.left ?? 0);
  const [scrollDirection, setScrollDirection] = useState<'down' | 'up' | 'initial'>('initial');

  const listener = (e) => {
    setBodyOffset(
      typeof window !== 'undefined' ? window.document.body.getBoundingClientRect() : null,
    );
    setScrollY(-1 * (bodyOffset?.top ?? 0));
    setScrollX(bodyOffset?.left ?? 0);
    setScrollDirection(lastScrollTop > -1 * (bodyOffset?.top ?? 0) ? 'down' : 'up');
    setLastScrollTop(-1 * (bodyOffset?.top ?? 0));
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', listener);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', listener);
      }
    };
  });

  return {
    scrollY,
    scrollX,
    scrollDirection,
  };
}
