import React from 'react';
import styled from 'styled-components';
import { FixedWidthContainer } from '../Container';

interface Background {
  backgroundColor?: string;
}

const Background = styled('div')<Background>`
  background-color: ${(p) => p.backgroundColor};
  width: 100%;
  height: 100%;
  margin: 16px 0;
`;

Background.defaultProps = {
  backgroundColor: '#fff',
};

interface PageLayout {
  children: React.ReactNode;
  backgroundColor: string;
  color: string;
}

const PageLayoutContainer = styled('div')<PageLayout>`
  color: ${(p) => p.color};
  background-color: ${(p) => p.backgroundColor};
  margin: 0 auto;
  padding: 48px;
  width: 100%;
`;

PageLayoutContainer.defaultProps = {
  color: '#000',
  backgroundColor: '#fff',
};

const PageLayout: React.FC<PageLayout> = ({ children, color, backgroundColor }: PageLayout) => (
  <Background backgroundColor={backgroundColor}>
    <FixedWidthContainer>
      <PageLayoutContainer color={color} backgroundColor={backgroundColor}>
        {children}
      </PageLayoutContainer>
    </FixedWidthContainer>
  </Background>
);

export default PageLayout;
