import * as React from 'react';
import * as CSS from 'csstype';
import { Link as GLink, GatsbyLinkProps } from 'gatsby';
import MLink from '@material-ui/core/Link';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
// import { fade } from '@material-ui/core/styles/colorManipulator';

interface MLinkProps {
  href?: string;
  target?: string;
}

const useStyles = ({}: MLinkProps) =>
  makeStyles<Theme, MLinkProps>((theme) =>
    createStyles({
      link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
          color: theme.palette.getContrastText('#222'),
          textDecoration: 'none',
        },
      },
    }),
  );

// navigate pathContext pageContext pageResources
// NOTE: maybe this can be used as component base interface?
export interface LinkProps extends MLinkProps {
  children?: React.ReactNode;
  className?: string;
  style?: CSS.Properties;
}

// FIXME: declare global or import
interface AppProps {
  navigate: any;
  pageContext: any;
  pageResources: any;
  pathContext: any;
}

const Link = (props: LinkProps & Partial<AppProps>) => {
  const {
    children,
    className,
    style,
    navigate,
    pageContext,
    pageResources,
    pathContext,
    ...mLinkProps
  } = props;
  const styles = useStyles(mLinkProps)(props);
  return (
    <MLink className={`${styles.link} ${className || ''}`} style={style} {...mLinkProps}>
      {children}
    </MLink>
  );
};

export { Link };
