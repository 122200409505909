import * as React from 'react';
import { Link as GLink } from 'gatsby';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';

import Container from '@material-ui/core/Container';

import { NavItem, NavItemProps } from '../NavItem';

const items: NavItemProps[] = [
  {
    label: 'Startseite',
    to: '/',
    href: '/',
  },
  {
    label: 'Leistungen',
    to: '/#services',
    href: '/',
  },
  {
    label: 'Transporte',
    to: '/#transports',
    href: '/',
  },
  {
    label: 'Covid19',
    to: '/#covid',
    href: '/',
  },
  {
    label: 'Über uns',
    to: '/#about',
    href: '/',
  },
  // {
  //   label: 'Fahrzeuge',
  //   to: '/#vehicles',
  //   href: '/',
  // },
  {
    label: 'Team',
    to: '/#team',
    href: '/',
  },
  {
    label: 'Kontakt',
    to: '/#kontakt',
    href: '/',
  },
  // { // TODO: => Footer but as link to separate sites
  //   label: 'Impressum',
  //   to: '/imprint',
  //   href: '/',
  // },
  // {
  //   label: 'Datenschutz',
  //   to: '/privacy-policy',
  //   href: '/',
  // },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    toolBar: {
      fontSize: 22,
      padding: '0 16px',
      minHeight: '96px',
      '& h6': {
        '& div': {
          '& > * + *': {
            // marginLeft: theme.spacing(2),
          },
        },
      },
    },
    // '.MuiToolbar-regular': {
    //   minHeight: '48px',
    // },
    linkButton: {
      // backgroundColor: '#f00',
      // color: '#0f0',
      // '&:hover': {
      //   backgroundColor: '#f00',
      //   color: '#0f0',
      // },
    },
    brand: {
      // padding: `0px ${theme.spacing(2)}px`,
      fontWeight: 'bold',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    navButtons: {
      // minHeight: '36px',
    },
  }),
);

export interface NavBarProps {
  isVisible: boolean;
  rootPagePaths: NavItemProps[];
  pagePaths: NavItemProps[];
  uri: string;
  orientation?: 'horizontal' | 'vertical';
  closeMenu?: () => void;
  style: any;
}

const NavBar = ({ isVisible, orientation, uri, style, closeMenu }: NavBarProps) => {
  // TODO: brandname from context nd from .env
  const brandName = 'MKF';
  const isSameRoute = (url: string, to: string): boolean => url === to;
  const classes = useStyles();
  const onButtonClick = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (closeMenu) {
      closeMenu();
    }
  };

  return (
    <Container>
      {isVisible && (
        <Toolbar className={classes.toolBar} style={{ ...style }}>
          <ButtonGroup
            className={classes.navButtons}
            orientation={orientation || 'horizontal'}
            aria-label='text primary button group'
            style={orientation && orientation === 'vertical' ? { paddingTop: '32px' } : {}}
          >
            {/* {!orientation ||
              (orientation && orientation !== 'vertical' && (
                <div className={classes.brand}>
                  <img
                    alt='MKF-Brand-Logo'
                    src={'./Logo.svg'}
                    style={{ width: '64px', height: '64px', margin: '0 24px' }}
                  />
                </div>
              ))} */}
            <div className={classes.brand}>
              <img
                alt='MKF-Brand-Logo'
                src={'/Logo.svg'}
                style={{ width: '64px', height: '64px', margin: '0 24px' }}
              />
            </div>
            {items.map(({ label, to, href }) => (
              <Button
                key={to}
                variant={'text'}
                className={classes.toolBar}
                // component={'button'}
                // color={isSameRoute(uri, to) ? 'primary' : 'secondary'}
                // variant='inherit'
                // href={href}
                // to={to}
                onClick={onButtonClick}
                style={
                  orientation && orientation === 'vertical'
                    ? { textDecoration: 'none', minHeight: '76px' }
                    : { textDecoration: 'none' }
                }
              >
                {/* <NavItem to={to} isActive={isSameRoute(uri, to)}>
                  {label}
                </NavItem> */}
                <GLink
                  to={to || ''}
                  // color={isSameRoute(uri, to) ? 'primary' : 'secondary'}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography
                    color={isSameRoute(uri, to) ? 'primary' : 'textPrimary'}
                    variant={isSameRoute(uri, to) ? 'button' : 'inherit'}
                  >
                    <span
                      style={
                        isSameRoute(uri, to)
                          ? { fontWeight: 1000, padding: '0 8px', fontSize: 22 }
                          : {}
                      }
                    >
                      {label}
                    </span>
                  </Typography>
                </GLink>
              </Button>
            ))}
          </ButtonGroup>
        </Toolbar>
      )}
    </Container>
  );
};

export { NavBar };
