import * as React from 'react';
import { CSSProperties } from 'styled-components';

export interface RowProps {
  children?: React.ReactNode;
  style?: CSSProperties;
}

/**
 * Row for flex container
 * @param RowProps
 */
const Row = ({ children, style }: RowProps) => (
  <div style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', ...style }}>
    {children}
  </div>
);

export { Row };
