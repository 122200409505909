import styled from 'styled-components';

interface FixedWidthContainerProps {
  maxWidth?: number;
}

const FixedWidthContainer = styled('div')<FixedWidthContainerProps>`
  margin: 0 auto;
  padding: 0 24px;
  max-width: ${(p) => p.maxWidth}px;
  width: 100%;
`;

FixedWidthContainer.defaultProps = {
  maxWidth: 800,
};

export { FixedWidthContainer, FixedWidthContainerProps };
