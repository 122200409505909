import * as React from 'react';

import AppBar from '@material-ui/core/AppBar';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import { fade } from '@material-ui/core/styles/colorManipulator';

import { Button } from 'components';
import { useBreakPointWidth, useScroll } from 'src/hooks';

// import { Brand, BrandProps } from './Brand'; // TODO:
import { NavBar, NavBarProps } from './NavBar';
import { NavItem, NavItemProps } from './NavItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: fade(theme.palette.common.white, 0.95),
    },
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
      display: 'flex',
      height: 224,
    },
    tabs: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  }),
);

// interface GQLSiteMetadata {
//   site: { siteMetadata: { name: string } };
// }

export interface NavigationProps {
  rootPagePaths: NavItemProps[];
  pagePaths: NavItemProps[];
  uri: string;
}

const Navigation: React.FC<NavigationProps> = (props: NavigationProps) => {
  const { scrollY } = useScroll();
  const classes = useStyles();
  const width = useBreakPointWidth();
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const isNavBarVisible = typeof window !== 'undefined' ? scrollY > window.innerHeight : true;

  // const isNavBarVisible = true;

  // const {
  //   site: {
  //     siteMetadata: { name },
  //   },
  // } = useStaticQuery<GQLSiteMetadata>(graphql`
  //   {
  //     site {
  //       siteMetadata {
  //         name
  //       }
  //     }
  //   }
  // `);

  // return null;

  return (
    <>
      <div id='back-to-top-anchor' />
      {width === 'xs' || width === 'sm' || width === 'md' ? (
        <>
          <AppBar className={classes.container}>
            <div
              style={{
                width: '100%',
                color: '#000',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  color={'primary'}
                  style={{
                    width: '100px',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '12px 0',
                    margin: '8px 0',
                    // alignItems: 'center',
                  }}
                >
                  <MenuIcon fontSize={'large'} />
                </Button>
              </div>
              <div
                style={{
                  width: '50%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  alt='MKF-Brand-Logo'
                  src={'/Logo.svg'}
                  style={{ width: '64px', height: '64px', margin: '0 24px' }}
                />
              </div>
            </div>
            {isMenuOpen && (
              <NavBar
                closeMenu={() => setIsMenuOpen(false)}
                isVisible
                {...props}
                orientation={'vertical'}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            )}
          </AppBar>
        </>
      ) : (
        <>
          <AppBar className={classes.container}>
            <NavBar isVisible={isNavBarVisible} {...props} />
          </AppBar>
          <AppBar
            className={classes.container}
            position='static' /** is left in place due to layout jumping */
            // FIXME: pseudo -> isOpticallyVisible={isNavBarVisible ? 'transparent' : 'normal'}
          >
            <NavBar isVisible {...props} />
          </AppBar>
        </>
      )}
    </>
  );
};

export { Navigation };
