import * as React from 'react';
// import styled from 'styled-components';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import MButton from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
  }),
);

export interface ButtonProps {
  children?: React.ReactNode;
  style?: any;
  color?: any;
}

// FIXME: declare global or import
interface AppProps {
  navigate: any;
  pageContext: any;
  pageResources: any;
  pathContext: any;
}

export default function Button(props: ButtonProps) {
  const { children, color, location, pathContext, style, ...rest } = props;
  // const classes = useStyles();

  // const scrollToHref = (href: string) => {
  //   if (pathContext.location.href.includes(href)) {
  //     this.$ref.scrollIntoView({
  //         // optional params
  //         behaviour: 'smooth',
  //         block: 'start',
  //         inline: 'center',
  //     });
  // }
  // }

  return (
    <MButton color={color || 'default'} style={style} variant='contained' {...rest}>
      {children}
    </MButton>
  );
}

// Create HOC
const AppPropsBlocker = (props: ButtonProps & Partial<AppProps>) => {
  const { children, navigate, pageContext, pageResources, ...passProps } = props;
  return <Button {...passProps}>{children}</Button>;
};

// const Button = styled('button')<ButtonProps>`
//   padding: 4px 12px;
//   margin: 0;
//   background: none;
//   border: none;
//   border: 2px solid #663399;
//   border-radius: 2px;
//   color: #663399;
//   font-size: 14px;
//   font-weight: 500;
//   letter-spacing: 0.4px;
//   line-height: 24px;
//   text-transform: uppercase;
//   transition: all ease-in-out 0.2s;
//   transition-property: color, background-color;

//   &:hover,
//   &:focus {
//     color: #fff;
//     background-color: #663399;
//   }
// `;

// Button.defaultProps = {
//   type: 'button',
// };

export { AppPropsBlocker as Button };
