import * as React from 'react';
import { CSSProperties } from 'styled-components';

import { Column, Row } from 'components';

export interface IconCardProps {
  children?: React.ReactNode;
  icon: React.ReactNode;
  style?: CSSProperties;
}

/**
 * A card with 2 columns - 1 for icon 1 for children
 * @param CardProps
 */
const IconCard = ({ children, icon, style }: IconCardProps) => (
  <Row style={style}>
    <Column>{icon}</Column>
    <Column>{children}</Column>
  </Row>
);

export { IconCard };
