import * as React from 'react';

import { ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';

import CssBaseline from '@material-ui/core/CssBaseline';
import { StylesProvider, createMuiTheme } from '@material-ui/core/styles';
import { red, blueGrey } from '@material-ui/core/colors';
import { ThemeProvider as ThemeProviderS } from '@material-ui/styles';

import { BaseCSS } from './BaseCSS';

import { theme } from './theme.constants';

const themeS = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#cc4a4a', // blueGrey[700],
    },
    secondary: {
      // This is green.A700 as hex.
      main: blueGrey[700], // red[500], '#cc4a4a'
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: false, // No more ripple, on the whole application 💣
    },
  },
  typography: {
    // -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important
    fontFamily: [
      '"Raleway", sans-serif',
      '"Montserrat", sans-serif',
      'Segoe UI',
      'Roboto',
      'Fira Sans',
      'Open Sans',
    ].join(','),
  },
  overrides: {
    MuiRadio: {
      root: {
        color: '#cc4a4a',
      },
      colorSecondary: {
        '&$checked': {
          color: 'green',
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*, *::before, *::after': {
          boxSizing: 'content-box',
        },

        /* Hide scrollbar for Chrome, Safari and Opera */
        'html::-webkit-scrollbar': {
          display: 'none',
        },

        /* Hide scrollbar for IE, Edge and Firefox */
        html: {
          '-ms-overflow-style': 'none' /* IE and Edge */,
          'scrollbar-width': 'none' /* Firefox */,
        },

        // body: {
        //   // backgroundColor: '#f0f',
        //   'font-family':
        //     '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        // },

        '.fullheight': {
          height: 'calc(var(--vh, 1vh) * 100)',
        },
      },
    },
  },
});

// const Theme: React.FC = ({ children }) => (
//   <StylesProvider injectFirst>
//     <ThemeProviderS theme={themeS}>
//       <ThemeProvider theme={theme}>
//         <>
//           {/* <Normalize /> */}
//           <CssBaseline />
//           {/* <BaseCSS /> */}
//           {children}
//         </>
//       </ThemeProvider>
//     </ThemeProviderS>
//   </StylesProvider>
// );

const Theme: React.FC = ({ children }) => (
  <StylesProvider>
    <ThemeProviderS theme={themeS}>
      <ThemeProvider theme={theme}>
        <Normalize />
        <CssBaseline />
        <BaseCSS />
        {children}
      </ThemeProvider>
    </ThemeProviderS>
  </StylesProvider>
);

export default Theme;
