// import * as React from 'react';
import * as CSS from 'csstype';
import styled, { DefaultTheme } from 'styled-components';

interface TextLineStyle {
  color: CSS.Property.Color;
  fontSize: CSS.Property.FontSize;
  fontWeight: CSS.Property.FontWeight;
  lineHeight: CSS.Property.LineHeight;
  marginBottom?: CSS.Property.MarginBottom;
  wordSpacing?: CSS.Property.WordSpacing;
  textAlign?: CSS.Property.TextAlign;
}

interface TextLineProps {
  theme?: DefaultTheme;
  className?: string;
  key: string;
  style?: TextLineStyle;
}

const TextLine = styled('div')<TextLineProps>`
  font-size: 24px;
  font-weight: normal;
  color: #eee;
  line-height: 48px;
  text-align: ${(p) => p?.style?.textAlign ?? 'center'};
`;

export { TextLine, TextLineProps, TextLineStyle };
