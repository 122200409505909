import * as React from 'react';

export interface ImageProps {
  children?: React.ReactNode;
  src: string;
  style?: CSSProperties;
}

/**
 * TEMPLATE FILE
 * @param TsxProps
 */
const Image = ({ children, src, style }: ImageProps) => (
  <div style={{ width: '100%' }}>
    <img
      alt={'img-'}
      src={src}
      style={{
        // backgroundImage: `url("${src}")`,
        // backgroundSize: 'contain',
        // backgroundPosition: 'center',

        width: '100%',
        height: 'auto',
        ...style,
      }}
    />
    {children}
  </div>
);

export { Image };
