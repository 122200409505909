import * as React from 'react';
import * as CSS from 'csstype';
import styled, { DefaultTheme } from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';

import { IndexPageNavigation } from 'src/mdx/indexPage.constants';

import { TextLine, TextLineProps } from '../Text';
import { FlexContainer } from '../Container';

const useStyles = makeStyles({
  button: {
    background: 'linear-gradient(45deg, #6B8BFE 30%, #6BBBFF 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
  },
});

const Wrapper = styled('div')<{ mode: ThemeMode }>`
  display: flex;
  flex-flow: nowrap column;
  margin-bottom: '64px';
  /* justify-content: center; */
  /* align-items: center; */

  padding: 24px 8px;
  padding-bottom: 64px;
  color: ${(p) => (p.mode === 'dark' ? '#eee' : '#111')};

  .foo {
    /* color: ${(p) => (p.mode === 'dark' ? '#eee' : '#f11')}; */
    background-color: '#0f0 !important';
  }

  @media (max-width: 648px) {
    flex-flow: nowrap column;
  }
`;

type ThemeMode = 'dark' | 'light';

type BlockHeadTextSize = 'sm' | 'md' | 'lg' | 'xl';

export const GetLinePropsByIndex = (
  index: number,
  mode: ThemeMode,
  size: BlockHeadTextSize,
  textAlign: CSS.Property.TextAlign,
): TextLineProps => {
  const getSizeBase = (s: BlockHeadTextSize): number => {
    switch (s) {
      case 'sm':
        return '12';
      case 'md':
        return '14';
      case 'lg':
        return '16';
      case 'xl':
      default:
        return '20';
    }
  };
  const getLineHeightBase = (s: BlockHeadTextSize): number => {
    switch (s) {
      case 'sm':
        return 14;
      case 'md':
        return 16;
      case 'lg':
        return 18;
      case 'xl':
      default:
        return 22;
    }
  };

  const getSizeHeading = (s: BlockHeadTextSize): number => {
    switch (s) {
      case 'sm':
        return 28;
      case 'md':
        return 32;
      case 'lg':
        return 36;
      case 'xl':
      default:
        return 50;
    }
  };
  const getLineHeightHeading = (s: BlockHeadTextSize): number => {
    switch (s) {
      case 'sm':
        return 28;
      case 'md':
        return 32;
      case 'lg':
        return 36;
      case 'xl':
      default:
        return 50;
    }
  };

  const baseConfig: TextLineProps = {
    key: `line-${index}`,
    style: {
      fontSize: `${getSizeBase(size)}px`,
      fontWeight: 'normal',
      color: mode === 'dark' ? '#eee' : '#111',
      lineHeight: `${getLineHeightBase(size)}px`,
      textAlign: textAlign ?? 'center',
      padding: '2px 0px',
      fontFamily: 'Raleway, sans-serif',
      // marginTop: '2px',
    },
  };
  switch (index) {
    case 0:
      return {
        ...baseConfig,
        style: {
          ...baseConfig.style,
          fontSize: `${getSizeHeading(size) - 4}px`,
          fontWeight: '10',
          lineHeight: `${getLineHeightHeading(size) - 4}px`,
          padding: 0,
          margin: 0,
          marginBottom: '-4px',
          // fontFamily: '"Raleway", sans-serif',
          letterSpacing: '-3px',
          // '@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap")': {},
        },
      };
    case 1:
      return {
        ...baseConfig,
        style: {
          ...baseConfig.style,
          fontSize: `${getSizeHeading(size)}px`,
          fontWeight: 'bold',
          fontWidth: '100px',
          color: mode === 'dark' ? '#fff' : '#000',
          lineHeight: `${getLineHeightHeading(size)}px`,
          wordSpacing: '0.025em',
          margin: 0,
          letterSpacing: '-3px',
          fontFamily: '"Montserrat", sans-serif',
        },
      };
    case 2:
    default:
      return baseConfig;
  }
};

interface BlockHeadProps {
  textAlign?: CSS.Property.TextAlign;
  children?: React.ReactNode;
  colors?: string[] | string;
  mode?: ThemeMode;
  size?: BlockHeadTextSize;
  title?: string[] | string;
}

const BlockHead: React.FC<BlockHeadProps> = ({
  children,
  mode,
  size,
  textAlign,
  title,
  style,
}: BlockHeadProps) => {
  const restProps = GetLinePropsByIndex(2, mode || 'light', size || 'lg', textAlign || 'center');
  restProps.style = { ...restProps.style, lineHeight: '16px', fontSize: '14px' };
  return (
    <Wrapper id={IndexPageNavigation.CONTACT} mode={mode || 'light'} style={style}>
      {title && Array.isArray(title)
        ? title.map((t: any, idx: number) => (
            <TextLine
              {...GetLinePropsByIndex(idx, mode || 'light', size || 'lg', textAlign || 'center')}
            >
              {t}
            </TextLine>
          ))
        : (
            <TextLine
              {...GetLinePropsByIndex(0, mode || 'light', size || 'lg', textAlign || 'center')}
            >
              {title}
            </TextLine>
          ) ?? null}
      {/* <div style={{ color: mode === 'dark' ? '#eee' : '#111' }}>{children}</div>
      <div style={{ color: mode === 'dark' ? '#eee' : '#111' }}>{children}</div> */}
      <TextLine {...restProps}>{children}</TextLine>
    </Wrapper>
  );
};

export { BlockHead, BlockHeadProps };
