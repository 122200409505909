export function init(): void {
  // TODO: get log level settings from .env
  if (
    typeof window !== 'undefined' &&
    window.dbg === undefined /** && environment !== 'production' */
  ) {
    // console.log('init debug');
    window.dbg = (s: string, o?: unknown[]) => {
      console.log(s, o);
    };
    // dbg('init debug complete');
  }
}
