import * as React from 'react';
import * as CSS from 'csstype';
import MBox from '@material-ui/core/Box';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
// import { fade } from '@material-ui/core/styles/colorManipulator';

interface MBoxProps {
  spacing?: number;
  spacingH?: number;
  spacingV?: number;
}

const useStyles = ({ spacing, spacingH, spacingV }: MBoxProps) =>
  makeStyles<Theme, MBoxProps>((theme) =>
    createStyles({
      box: {
        // backgroundColor: `${fade(theme.palette.primary.main, 0.95)} !important`, // use for debug with important
        padding: `${theme.spacing(spacingV || spacing || 2)}px
          ${theme.spacing(spacingH || spacing || 2)}px`,
      },
    }),
  );

export interface BoxProps extends MBoxProps {
  children?: React.ReactNode;
  className?: string;
  style?: CSS.Properties;
}

const Box = (props: BoxProps) => {
  const { children, className, style, ...mBoxProps } = props;
  const styles = useStyles(mBoxProps)(props);
  return (
    <MBox className={`${styles.box} ${className || ''}`} style={style}>
      {children}
    </MBox>
  );
};

export { Box };
