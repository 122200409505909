export enum IndexPageNavigation {
  INTRO = 'intro',
  NAVIGATION = 'navigation',
  INTRODUCTION = 'introduction',
  ADDED_VALUE = 'added-value',
  SKILLS = 'skills',
  TEAM = 'team',
  STATS = 'stats',
  PHILOSOPHY = 'philosophy',
  CAPABILITIES = 'capabilities',
  CAPABILITY_OVERVIEW = 'capability-overview',
  CALL_TO_ACTION = 'call-to-action',
  FEEDBACK_QUOTES = 'feedback-quotes',
  CONTACT = 'contact',
  FOOTER = 'footer',
}
