import * as React from 'react';
import MContainer from '@material-ui/core/Container';
import { CSSProperties } from '@material-ui/styles';

export interface ContainerProps {
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
}

/**
 * A responsive container composed from MaterialUI
 * @param ContainerProps
 */
const Container: React.FC<ContainerProps> = ({ children, className, style }: ContainerProps) => (
  <MContainer className={className} style={style}>
    {(children as any) /** cast to any - stupid MUI */ || null}
  </MContainer>
);

export { Container };
