import * as React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Link, Typography, Container, Button } from 'components';

const styles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#222',
      textAlign: 'center',
      color: theme.palette.getContrastText('#222'),
      width: '100%',
      '@media (max-width: 648px)': {
        textAlign: 'left',
      },
    },
    linkContainer: {
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
  }),
);

export interface FooterProps {}

const Footer: React.FC<FooterProps> = (props: FooterProps) => (
  <div className={styles(props).root}>
    <Container>
      <Grid container spacing={4}>
        <Grid item sm={6}>
          <div className={styles(props).linkContainer}>
            <Link href='/imprint'>
              <Typography>Impressum</Typography>
            </Link>
          </div>
          <div className={styles(props).linkContainer}>
            <Link href='/privacy-policy'>
              <Typography>Datenschutz</Typography>
            </Link>
          </div>
        </Grid>
        <Grid item sm={6}>
          {/* <div className={styles(props).linkContainer}>
            {'Made with ❤️ by '}
            <Link href='https://willkuerlich.com' target='_blank' rel='noopener noreferrer'>
              {'Willkuerlich.com'}
            </Link>
          </div> */}
          <div className={styles(props).linkContainer}>
            <Typography>{'Copyright © 2020 MKF - Mobiler Kranken Fahrservice'}</Typography>
          </div>
          <div>
            <Button
              component={'a'}
              color={'primary'}
              href={`mailto:info@mkf-service.com?subject=%3CAnfrage%20via%20Website-Email%20Kontaktformular%3E%20Betreff:%20&body=${`
          --- Sie können Ihre Telefonnummer mitsenden, wir rufen Sie gerne zurück! ---
          `}`}
              // size={'large'}
              variant={'text'}
              style={{
                // padding: '16px 32px',
                // marginBottom: '96px',
                color: '#fff',
                // fontWeight: 'bold',
                // fontSize: '26px',
                textAlign: 'center',
              }}
            >
              <Typography>{'Kontakt'}</Typography>
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  </div>
);

export { Footer };
