import * as CSS from 'csstype';
import styled from 'styled-components';

interface FlexContainerProps {
  flexDirection?: CSS.Property.FlexDirection;
  padding?: CSS.Property.Padding;
}

// TODO: use Box https://material-ui.com/system/flexbox/ <Box display="flex"> or merge this with Box.tsx and move col and row
const FlexContainer = styled('div')<FlexContainerProps>`
  display: flex;
  flex-flow: nowrap ${(p) => p.flexDirection ?? 'row'};
  align-items: center;
  padding: ${(p) => p?.padding ?? 0};
`;

export { FlexContainer, FlexContainerProps };
