import * as React from 'react';
import MPaper from '@material-ui/core/Paper';

// import { DebugContainer, DebugOptions } from 'src/components.private/Debug';

// interface PaperOwnProps {
//   debug?: DebugOptions;
// }

export interface MaterialUIProps {
  children?: React.ReactNode;
  classes?: Record<string, unknown>; // TODO: CSS declaration needed
  component?: HTMLElementTagNameMap; // html element
  elevation?: number; // 0-24
  style: any;
  square?: boolean;
  variant?: 'elevation' | 'outlined';
}

export type PaperProps = MaterialUIProps;
// export type PaperDProps = MaterialUIProps & PaperOwnProps;

// const bla: React.FunctionComponent = () => ()

/**
 * A paper element composed from MaterialUI
 * @param PaperProps https://material-ui.com/api/paper/
 */
// & { ref: React.ForwardRefRenderFunction<{}, PaperProps> }
const Paper: React.FC<PaperProps> = (props: PaperProps) => {
  const { children, classes, component, elevation, style, square, variant } = props;
  /* ref={ref} */
  if (component) {
    return (
      <MPaper
        classes={classes}
        component={component as any}
        elevation={elevation || 0}
        square={square}
        variant={variant}
      >
        {children}
      </MPaper>
    );
  }
  return (
    <MPaper
      classes={classes}
      elevation={elevation || 0}
      style={style}
      square={square}
      variant={variant}
    >
      {children}
    </MPaper>
  );
};

// const PaperDebugged: React.FC<PaperDProps> = (props: PaperDProps) => {
//   const { debug, children, elevation } = props;
//   // const debuggedElem = React.useRef<HTMLDivElement>(null); // TODO: infer this from MUI "as" property / pass that property?

//   if (!debug) {
//     return <>{children}</>;
//   }

//   const paperFR = React.forwardRef((p, ref: any) => (
//     <Paper ref={ref} {...(props as any)} elevation={elevation || 0}>
//       {p.children}
//     </Paper>
//   ));

//   return (
//     <DebugContainer<PaperProps> elem={paperFR || null} {...debug}>
//       {children}
//     </DebugContainer>
//   );
// };

export { Paper };
