import { PageProps } from 'gatsby';
import * as React from 'react';

import StyleContextProvider from 'src/context/Style';
import ThemeProvider from 'src/context/Theme';
import { Background, Container, Navigation } from 'components';
import { init } from 'src/lib/debug';

import { Seo, SeoObject as ISeoObject, SeoProps } from './Seo';

export interface SiteProps extends SeoProps {
  fluid?: boolean;
  path: PageProps['path'];
}

const Site: React.FC<SiteProps> = ({ location, pageContext, path, seo, children, fluid }) => {
  init();
  return (
    <StyleContextProvider>
      <ThemeProvider>
        <Seo location={location} pageContext={pageContext} seo={seo} />
        <Background>
          <Navigation rootPagePaths={[]} pagePaths={[]} uri={path} />
          {fluid ? <Container>{children}</Container> : children}
        </Background>
      </ThemeProvider>
    </StyleContextProvider>
  );
};

export type SeoObject = ISeoObject;

export { Site };
