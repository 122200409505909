import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/styles';

import { Container, BlockHead, Box, Grid } from 'components';
import { useBreakPointWidth } from 'src/hooks';
// import { fade } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      minHeight: '100vh',
      // backgroundColor: '#22222244',
    },
    box: {
      backgroundColor: '#222222cc',
      // position: 'relative',
      // left: '0',
      // top: '24vh',
      // width: '28vw',
      // paddingTop: '96px',
      wordBreak: 'normal',
      padding: '0 64px',
      marginTop: '100px',
      minHeight: '35vh',
    },
    box2: {
      // backgroundColor: '#222222cc',
      // position: 'relative',
      // left: '0',
      // top: '24vh',
      // width: '28vw',
      // paddingTop: '96px',
      wordBreak: 'normal',
      padding: '0 64px',
      marginTop: '100px',
      // minHeight: '50vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    text: {},
  }),
);

export interface IntroProps {
  // background-attachment: fixed; // TODO
  // backgroundSource?: string[] | string;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  text?: string | string[];
}

const Intro: React.FC<IntroProps> = (props: IntroProps) => {
  const { backgroundSource, children, text } = props;
  const styles = useStyles();
  const width = useBreakPointWidth();
  console.log('width: ', width);
  const getSize = () => {
    switch (width) {
      case 'xl':
        return 'xl';
      case 'lg':
        return 'lg';
      case 'md':
        return 'md';
      case 'xs':
      case 'sm':
      default:
        return 'sm';
    }
  };
  return (
    <div className={styles.background}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box className={styles.box}>
              <BlockHead
                style={{ padding: '64px 32px', paddingBottom: '32px' }}
                textAlign={'left'}
                mode={'dark'}
                size={getSize()}
                className={styles.text}
                title={text}
              >
                {''}
              </BlockHead>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              className={styles.box2}
              style={width === 'lg' || width === 'xl' ? { minHeight: '50vh' } : {}}
            >
              {children}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export { Intro };
