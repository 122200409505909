import * as React from 'react';

export interface BackgroundProps {
  children?: React.ReactNode;
}

const Background = (props: BackgroundProps) => {
  // console.log('background props: ', props);
  // const { width, height } = useWindowSize(0, 0);
  return (
    <div
      style={{
        // height: '100vh',
        backgroundImage: `url("${'./backgroundPattern.webp'}"), url("${'./background.webp'}")`,
        // backgroundSize: 'initial, auto',
        backgroundRepeat: 'repeat, space',
        backgroundPosition: 'center, center',
        backgroundAttachment: 'fixed, fixed',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 0,
      }}
    >
      {props?.children ?? null}
    </div>
  );
};

export { Background };
