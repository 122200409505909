import React from 'react';

import { BaseThemeState } from './styleContext.types';
import { ThemeMode } from './styleContext.constants';
import StyleContext from './styleContext';

function GetThemeState(): BaseThemeState {
  const [themeMode, setThemeMode] = React.useState<ThemeMode>(ThemeMode.LIGHT);
  return { themeMode, setThemeMode };
}

function SettingsContextProvider({ children }: { children: React.ReactNode }): React.ReactElement | null {
  return (
    <StyleContext.Provider
      value={{
        ...GetThemeState(),
      }}
    >
      {children}
    </StyleContext.Provider>
  );
}

export default SettingsContextProvider;
