import * as React from 'react';
import { Link } from 'gatsby';
import firebase from 'firebase/app';
import * as CSS from 'csstype';
import Checkbox from '@material-ui/core/Checkbox';
import { useForm, Controller } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
// import { fade } from '@material-ui/core/styles/colorManipulator';
import { Button, TextInputField, Row } from 'components';
import { useBreakPointWidth } from 'src/hooks';

// import emailjs from 'emailjs-com';
// import { rewind } from 'react-helmet';
// import { getOrInitFirebaseInstance } from 'src/lib/firebase';

const useStyles = makeStyles<Theme>((theme) =>
  createStyles({
    form: {
      // backgroundColor: `${fade(theme.palette.primary.main, 0.95)} !important`, // use for debug with important
      // padding: `${theme.spacing(spacingV || spacing || 2)}px
      //     ${theme.spacing(spacingH || spacing || 2)}px`,
    },
  }),
);

export interface FormProps {
  children?: React.ReactNode;
  className?: string;
  style?: CSS.Properties;
  config: {name: string; fields: FormControlConfig[]};
}

enum FormType {
  boolean = 'boolean',
  string = 'string',
  textarea = 'textarea',
  date = 'Date',
}

interface FormControlConfig<T = boolean | string | Date> {
  id: string; // also => name
  label: string;
  as?: React.FunctionComponent; // string or renderCB is also ok
  defaultValue?: T;
  color?: string;
  variant?: string;
  rules: FormRules;
  type: FormType;
}

interface FormRules {
  required: boolean;
  // TODO:
}

// type FormData = {
//   example: string;
//   exampleRequired: string;
// };

// const config: FormControlConfig[] = [
//   {
//     id: '',
//     label: '',
//     as: TextInputField,
//     defaultValue: '',
//     color: 'secondary',
//     variant: 'filled',
//     rules: { required: true },
//     type: FormType.string,
//   },
// ];

interface FormValues {
  from_name: string;
  patient_name: string;
  phone: string;
  from_email: string;
  from_address_street: string;
  from_address_location: string;
  target_address_street: string;
  target_address_location: string;
  at_date: string;
  at_time_pickup: string;
  at_time_target: string;
  transport_type: string;
  transport_document: string;
  message: string;
}

const Form = (props: FormProps) => {
  const { children, className, config, style, ...passProps } = props;
  const styles = useStyles();
  // const [isOpen, setIsOpen] = React.useState<boolean>(false);
  // const [isSubmitSuccessful, setIsSubmitSuccessful] = React.useState<boolean>(false);
  const [hasReadPrivacyPolicy, setHasReadPrivacyPolicy] = React.useState<boolean>(false);
  // const [isFormValid, setIsFormValid] = React.useState<boolean>(false);
  const { register, handleSubmit, watch, errors, control } = useForm<FormValues, {}>({
    defaultValues: {},
    mode: 'all',
    reValidateMode: 'onSubmit',
    shouldFocusError: true,
    criteriaMode: 'all',
  });
  // const onSubmit = (data) => console.log(data);
  const width = useBreakPointWidth();

  const hasErrors = errors && Object.getOwnPropertyNames(errors).length > 0 ? true : false;

  const watchedValues = watch();

  const sendMail = (formData: FormValues) => {
    // if(e) {
    //   e.preventDefault();
    // }
    try {
      // console.log('sending mail formData: ', formData);
      if (typeof window !== 'undefined' && firebase) {
        const sendEmail = firebase.functions().httpsCallable('sendTransportFormAsMail');
        sendEmail({ ...formData }).then((res) => alert('Anfrage versendet!')).catch((err) => alert('Fehler beim Versand der Email'));
        // console.log('errors: ', errors)
      }
    } catch (err) {
      console.warn(err);
    }


    // try {
    //   console.log('sending watchedvalues: ', watchedValues);
    //   const fetchUrlDEV = 'http://localhost:5001/mobiler-kranken-fahrservice/europe-west3/sendTransportFormAsMail';
    //   const fetchUrlPROD = 'https://europe-west3-mobiler-kranken-fahrservice.cloudfunctions.net/sendTransportFormAsMail';
    //   const res = await fetch(fetchUrlPROD,
    //     {
    //       method: 'POST', // GET, POST, PUT, DELETE
    //       mode: 'no-cors', // no-cors, cors, same-origin
    //       cache: 'no-cache', // default, no-cache, reload, force-cache, only-if-cached
    //       // credentials: 'same-origin', // include, same-origin, omit
    //       credentials: 'omit',
    //       headers: {
    //         'Content-Type': 'application/json',
    //         // 'Content-Type': 'application/x-www-form-urlencoded',
    //       },
    //       redirect: 'follow', // manual, follow, error
    //       referrerPolicy: 'no-referrer', // no-referrer, no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    //       body: JSON.stringify({foo: 'bar'}), // body data type must match "Content-Type" header
    //     }
    //   );
    //   console.log('res: ', res);
    //   const result = await res.json();
    //   if(res) {
    //     console.log('fetch res: ', res);
    //   }
    //   if(result) {
    //     console.log('fetch result: ', result);
    //   }
    // } catch (err) {
    //   console.log('error: ', err)
    // }
  }

  // const send3 = async () => {
  //   // if(e) {
  //   //   e.preventDefault();
  //   // }
  //   // const endUserEmail = watchedValues.from_email || null;
  //   try {
  //     console.log('sending watchedvalues: ', watchedValues);
  //     const fetchUrlDEV = 'http://localhost:5001/mobiler-kranken-fahrservice/europe-west3/sendTransportFormAsMail';
  //     const fetchUrlPROD = 'https://europe-west3-mobiler-kranken-fahrservice.cloudfunctions.net/sendTransportFormAsMail';
  //     let result = null;
  //     fetch(fetchUrlDEV,
  //       {
  //         method: 'POST', // *GET, POST, PUT, DELETE, etc.
  //         mode: 'no-cors', // no-cors, *cors, same-origin
  //         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  //         // credentials: 'same-origin', // include, *same-origin, omit
  //         credentials: 'omit',
  //         headers: {
  //           'Content-Type': 'application/json; charset=UTF-8',
  //           // 'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //         redirect: 'follow', // manual, *follow, error
  //         referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  //         body: JSON.stringify({...watchedValues}), // body data type must match "Content-Type" header
  //       }
  //     ).then(function (response) {
  //       if (response.ok) {
  //         return response.json();
  //       }
  //       return Promise.reject(response);
  //     }).then(function (data) {
  //       console.log(data);
  //       result = data;
  //     }).catch(function (error) {
  //       console.warn('Something went wrong.', error);
  //     });

  //     // const result = await fetchData.json();
  //     if(result) {
  //       console.log('fetch result: ', result);
  //     }
  //   } catch (err) {
  //     console.log('error: ', err)
  //   }
  // }

  // const send = async (e: any) => {
  //   e.preventDefault();
  //   const form: HTMLFormElement = document.querySelector('#myform');
  //   console.log('e: ', e);
  //   console.log('e.target: ', e.target);
  //   const message = 'DummyText';
  //   const senderName = 'Willkuerlo';
  //   const senderEmail = 'haegele.w@web.de';
  //   const subject = 'Wichtig!';

  //   const receiverEmail = 'haegele.w@web.de';

  //   const msg = `email — ${senderEmail} ${senderName} ‘<br>’ Summary: ${message}`;


  //   console.log('xxx: ', Object.getOwnPropertyNames(Object.getOwnPropertyDescriptors(e.target)).filter(v => !isNaN(parseInt(v))));
  //   const validFieldIds = Object.getOwnPropertyNames(Object.getOwnPropertyDescriptors(e.target)).filter(v => !isNaN(parseInt(v)));
  //   const validFields = validFieldIds.map(vfIdx => Object.getOwnPropertyDescriptor(e.target, vfIdx));
  //   console.log('validFields: ', validFields);

  //   const newData = {};

  //   validFields.forEach(({value}) => {
  //     console.log('elem value: ', value);
  //     newData[value.id] = value.value;
  //   });

  //   console.log('newData: ', newData);

  //   try {
  //     const params = {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: JSON.stringify({
  //         service_id: 'default_service',
  //         template_id: 'template_wnx4tjs',
  //         user_id: 'user_93IrBPDvPrSAWZr4T0uA4',
  //         // from: 'haegele.w@web.de',
  //         // from_name: 'Blabbo',
  //         // reply_to: 'haegele.w@web.de',
  //         template_params: {
  //           ...newData,
  //           // from: 'haegele.w@web.de',
  //           // from_name: 'Blabbo',
  //           // reply_to: 'haegele.w@web.de',

  //           // from_name: 'haegele.w@web.de',
  //           // from: 'Willkür',
  //           phone: 'Geht dich nix an',
  //             // 'username': 'James',
  //             // 'g-recaptcha-response': '03AHJ_ASjnLA214KSNKFJAK12sfKASfehbmfd...'
  //         }
  //       }),
  //       // contentType: false, // auto-detection
  //       // processData: false // no need to parse formData to string
  //     };
  //     console.log('params: ', params);
  //     const resApi = await fetch('https://api.emailjs.com/api/v1.0/email/send', params);
  //     console.log('resApi: ', resApi)
  //     return;
  //     const res = await emailjs.sendForm(
  //       'service_nlqzyjs',
  //       'template_wnx4tjs',
  //       // formData,
  //       e.target,
  //       // {
  //       //   from_name: 'FromName',
  //       //   from: senderEmail,
  //       //   to: 'To',
  //       //   to_name: 'ToName',
  //       //   reply_to: senderEmail,
  //       //   subject: subject,
  //       //   message_html: msg,
  //       //   bodyText: msg,
  //       //   in_reply_to: '',
  //       //   recipient_address: 'haegele.w@web.de',
  //       // },
  //       // {
  //       //   user_name: senderName,
  //       //   user_email: senderEmail,
  //       //   subject: subject,
  //       //   senderEmail,
  //       //   receiverEmail,
  //       //   message: msg
  //       // },
  //       'user_93IrBPDvPrSAWZr4T0uA4');
  //     console.log('res: ', res);
  //   } catch (err) {
  //     console.warn(err);
  //   }
  // }

  const getDefaultValue: (type: FormType) => boolean | string | Date = (type: FormType) => {
    switch(type) {
      case FormType.date:
        return new Date(0);
      case FormType.boolean:
        return false;
      case FormType.string:
        default:
        return '';
    }
  }
  // FIXME:
  const getRenderComponent: (type: FormType) => React.FunctionComponent = (type: FormType) => {
    switch(type) {
      case FormType.date:
        return () => <TextInputField />;
      case FormType.boolean:
        return () => <TextInputField />;
      case FormType.string:
        default:
        return () => <TextInputField />;
      
    }
  }

  const isFormValid = () => {
    return !!(
      watchedValues.from_name && watchedValues.from_name.length > 0 &&
      watchedValues.patient_name && watchedValues.patient_name.length > 0 &&
      watchedValues.phone && watchedValues.phone.length > 0 &&
      watchedValues.from_email && watchedValues.from_email.length > 0 &&
      watchedValues.from_address_street && watchedValues.from_address_street.length > 0 &&
      watchedValues.from_address_location && watchedValues.from_address_location.length > 0 &&
      watchedValues.target_address_street && watchedValues.target_address_street.length > 0 &&
      watchedValues.target_address_location && watchedValues.target_address_location.length > 0 &&
      watchedValues.at_date && watchedValues.at_date.length > 0 &&
      watchedValues.at_time_pickup && watchedValues.at_time_pickup.length > 0 &&
      watchedValues.at_time_target && watchedValues.at_time_target.length > 0 &&
      watchedValues.transport_type && watchedValues.transport_type.length > 0 &&
      watchedValues.transport_document && watchedValues.transport_document.length > 0 &&
      watchedValues.message && watchedValues.message.length > 0
    ) ? true : false;
  }

  return (
    <form
      id={'myform'}
      onSubmit={handleSubmit(sendMail)}
      className={`${styles.form} ${className || ''}`}
      style={{...style, backgroundColor: '#222222cc', padding: '16px', marginBottom: '16px', borderRadius: '8px'}}
      {...passProps}
    >
      
      {/* <div style={{
        width: '100%',
        fontWeight: 'bold',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: '32px 0'
      }}>
        <img
          alt='MKF-Brand-Logo'
          src={'./Logo.svg'}
          style={{ width: '64px', height: '64px', margin: '0 24px' }}
        />
      </div> */}
      <div style={{color: '#fff', width: '100%', textAlign: 'center', padding: '32px 0' , fontSize: width === 'xs' ? '0.8em': '1.4em'}}><h1>Transportanfrage-Formular</h1></div>
      {config &&
        config.map((cfg) => {
          return (
            <>
              <h2 style={{color: '#fff', marginTop: '32px', padding: '0 16px'}}>{cfg.name}</h2>
              { cfg.fields &&
                cfg.fields.map((cfg) => {
                  if(cfg.type && cfg.type === 'radioGroup') {
                    return (
                      <FormControl
                        // key={id}
                        // variant={'outlined'}
                        variant={cfg.variant}
                        // error={!!errors.item_ids?.message}
                        style={{ width: '100%' }}
                      >
                        <FormHelperText style={{color: 'white'}}><Typography>{cfg.label ?? ''}</Typography></FormHelperText>
                        <Controller
                          disabled={!!cfg.isDisabled}
                          id={cfg.id}
                          name={cfg.id}
                          control={control}
                          label={cfg.label} // i18n
                          variant={cfg.variant}
                          // fullWidth
                          rules={cfg.rules || {}}
                          // error={errors && errors[id] ? errors[id].message || ' ' : null}
                          defaultValue={cfg.defaultValue || false}
                          as={
                            <RadioGroup aria-label={cfg.id}>
                              <Grid container style={{ padding: '16px 32px' }}>
                                {cfg && cfg.options?.length
                                  ? cfg.options.map((option) => (
                                      <Grid key={option.id} item xs={12} md={4}>
                                        <FormControlLabel
                                          disabled={!!(!!cfg.isDisabled || !!option.isDisabled) || false}
                                          value={option.id}
                                          control={<Radio />}
                                          label={option.label}
                                          style={{color: 'white'}}
                                        />
                                      </Grid>
                                    ))
                                  : null}
                              </Grid>
                            </RadioGroup>
                          }
                        />
                      </FormControl>
                    );
                  }
                  return (
                    <div style={{ padding: '32px 16px' }}>
                      <Controller
                        // TODO: reexport with MUI props
                        fullWidth
                        key={cfg.id}
                        id={cfg.id}
                        name={cfg.id}
                        control={control}
                        as={cfg.as || getRenderComponent(cfg.type)}
                        defaultValue={cfg.defaultValue || getDefaultValue(cfg.type)}
                        color={'secondary'}
                        label={cfg.label || ''}
                        variant='filled'
                        style={{ padding: '4px' }}
                        rules={cfg.rules || {}}
                        error={!!errors.[cfg.id]}
                        multiline={!!cfg.multiline}
                      />
                    </div>
                  );
                })
              }
            </>
          );
        })
      }

      <Row>{children}</Row>
      <Row>
        <div>
          <div style={{ color: '#fff', margin: '16px 0', textAlign: 'center' }}>
            <Typography>
              {'Felder markiert mit * sind notwendig um einen Transport anzufordern'}
            </Typography>
          </div>
          <div style={{ color: '#fff', margin: '16px 0', textAlign: 'center', display: 'flex', flexDirectiom: 'row', alignItems: 'center' }}>
            <FormControlLabel checked={hasReadPrivacyPolicy} onChange={() => setHasReadPrivacyPolicy(!hasReadPrivacyPolicy)} size={'small'} control={<Checkbox style={{color: '#fff', padding: '0'}} name='checkedPP' />} label='' />
            <Typography>
              Ich habe die <Link target={'_blank'} href={'privacy-policy'} style={{color: '#cc4a4a'}}>Datenschutzbestimmungen</Link> gelesen und bin damit einverstanden:
            </Typography>
          </div>
        </div>
      </Row>
      {hasErrors && (<Row><span style={{color: '#cc4a4a', fontWeight: 'bold', paddingTop: '32px'}}>Bitte alle mit (*) markierten Pflichtfelder ausfüllen</span></Row>)}
      <Row>
        {/* <Button
          type='submit'
          disabled={hasErrors}
          color={'primary'}
          // onClick={() => sendEmail('foo')}
          size={'large'}
          style={{
            padding: '32px 32px',
            marginBottom: '64px',
            color: hasErrors ? '#cc4a4a' : '#fff',
            // backgroundColor: hasErrors ?  '555' : '#cc4a4a',
            fontWeight: 'bold',
            fontSize: '26px',
            textAlign: 'center',
            margin: '32px 16px',
            textDecoration: 'none',
          }}
        >
          {hasErrors ? '( Angaben unvollständig )' : 'TRANSPORT ANFORDERN'}
        </Button> */}
        <Button
          type='submit'
          disabled={hasErrors || !hasReadPrivacyPolicy || !isFormValid()}
          color={'primary'}
          // onClick={() => sendMail()}
          size={'large'}
          style={{
            padding: '32px 32px',
            marginBottom: '64px',
            color: hasErrors ? '#cc4a4a' : '#fff',
            // backgroundColor: hasErrors ?  '555' : '#cc4a4a',
            fontWeight: 'bold',
            fontSize: '26px',
            textAlign: 'center',
            margin: '32px 16px',
            textDecoration: 'none',
          }}
        >
          {hasErrors ? '( Angaben unvollständig )' : 'TRANSPORT ANFORDERN'}
        </Button>
      </Row>
    </form>
  );
};

export { Form };
